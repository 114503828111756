.outer-bigger-container {
  /* border: 2px solid gray; */
  margin: 54px;
}

.bigger-container {
  border-radius: 5px 5px 0px 0px;
  border: 2px solid gray;
  background-color: #241887;
  color: aliceblue;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
}

.outer-inner-container {
  display: flex;
  border: 2px solid lightgray;
  justify-content: space-between;
  padding: 0px 20px;
}

.templatename {
  font-weight: bold;
  padding: 12px;
}

.button-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
}

.button-box-inner {
  margin: 5px 10px;
}
.create-new-box {
  margin: 20px 15px;
  text-align: right;
}
.setting_details_table {
  margin-bottom: 100px;
}
