/* .Sidebar{
    width:250px; 
    height: 100%;
    padding-top: 30px;
    padding-bottom: 55px;
    text-align: center;
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: semi-bold;
    letter-spacing: 1px;
    background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
}

.SidebarList{
  
    height: 100%;
    width: 100%;
    padding: 0vh;

}

.SidebarList .row {
    width: 100%;
    height: 55px;
    list-style-type: none;
    margin: 0;
    display:flex;
    flex-direction: row;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.SidebarList .row:hover {
    cursor: pointer;
    background-color: black;
}

.SidebarList .row #active{
    background-color: black;
} */

.dashboard {
  background-color: #e5e5e5;
}
.dashboard-main {
  display: grid;
  grid-template-columns: 19vw 1fr;
}
