.timeline {
  padding: 0 !important;
}

.timeline .timeline_header {
  padding-top: 7px;
}

.timeline .timeline_dot_header {
  background: transparent !important;
  border: transparent !important;
  padding: 0px !important;
}

/* .timeline .timeline_firstItem {
    min-height: 80px !important;
} */

.timeline .timeline_firstItem {
  min-height: 0px !important;
}

.timeline .MuiTimelineItem-root {
  min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

/* remaining items */

.timeline .timeline_dot {
  background-color: #003a69 !important;
}

.timeline .MuiTimelineConnector-root {
  background-color: #003a69 !important;
  min-height: 30px !important;
}

.timeline .separator_padding {
  padding-left: 8px !important;
}

.timeline .separator_content_padding {
  padding-left: 3px !important;
}

.timeline .MuiTimelineContent-root {
  padding: 6px 0px 0px 10px !important;
  min-height: 0px !important;
}
