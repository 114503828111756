.admin-enrollment-main {
  display: flex;
  flex-direction: row;
}
.admin-upload-material {
  font-family: "Readex Pro", sans-serif;
  display: flex;
  margin-right: 30px;
  flex-direction: column;
  width: 350px;
  margin-left: auto;
  margin-top: 1.5em;
}
.admin-drop-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 168px;
  background: #f8f8ff;
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
}

.fileList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}
