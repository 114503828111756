/* @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap"); */

.bgImage {
  background-image: url(../../../utilities/images/Rectangle\ 136.png);
  height: 170px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* font-family: "Readex Pro", sans-serif; */

/* .course {
  height: 100vh;
  background-color: #f6f6f6;
}

.course-main {
  height: 90vh;
  display: grid;
  grid-template-columns: 18vw 1fr;
  background-color: #f6f6f6;
}

.course-main-cnt-search {
  height: 9vh;
  display: grid;
  justify-content: right;
  align-items: center;
}

.course-main-cnt-search input {
  border-radius: 24px;
  background-color: white;
  height: 5vh;
  width: 20vw;
  border: none;
  text-align: center;
  font-family: "Readex Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.course-main-cnt-search input::placeholder {
  color: #d7d7d7;
}

.course-main-cnt-search form {
  display: grid;
  align-items: center;
  justify-content: right;
  position: relative;
}
.course-main-cnt-search input {
  margin-right: 3vw;
  margin-top: 0.5vw;
}

.course-main-cnt-search svg {
  position: absolute;
  right: 18vw;
  top: 2.5vh;
}

.course-main-cnt-center-grid-item-vid img {
  height: 20vh;
  width: 13vw;
  border-radius: 16px;
}

.course-main-cnt-center-grid-item {
  width: 13vw;
}
.course-main-cnt-center-grid-item-desc {
  display: grid;
}

.course-main-cnt-center-grid-item-desc-item-one {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.course-main-cnt-center-grid-item-desc-item-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.course-main-cnt-center-grid-item-desc-item-one-name {
  font-family: "Readex Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #717579;
  font-weight: 700;
  text-align: left;
}

.course-main-cnt-center-grid-item-desc-item-two-companyname {
  color: #d7d7d7;
  font-size: 10px;
  text-align: left;
  font-weight: 600;
}

.course-main-cnt-center-grid-item-desc-item-two-hrs {
  color: #d7d7d7;
  font-size: 10px;
  font-weight: 600;
  text-align: right;
}

.course-main-cnt-center-grid-item-desc-item-one-progress {
  text-align: right;
}

.course-main-cnt-center-grid-item-btn button {
  width: 10vw;
  height: 5vh;
  background-color: #007848;
  border-radius: 20px;
  border: none;
  font-family: "Readex Pro", sans-serif;
  color: white;
  font-size: 0.7rem;
  margin: 14px;
}

.course-main-cnt-ceter {
  display: grid;
  place-items: center;
}

.course-main-cnt-center-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  width: 70vw;
  margin: 10px 10px;
}

.course-main-cnt-viewall {
  height: 6vh;
  text-align: center;
}

.course-main-cnt-center-grid-viewall {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 70vw;
  margin: 10px 10px;
  overflow: scroll;
  place-items: center;
  height: 72vh;
} */
