/* .section{
    width: max-content;
    margin: 5%;
    padding: 16px;
    text-align: left;
    border: 2px solid #ffffff;
    border-radius: 2px;
}
input{
    padding: 2% 5%;
    margin-bottom: 5%;
}
textarea{
    max-lines: 3;
    padding: 2% 5%;
}
h2,p{
    margin-bottom: 10px;
}
select{
    padding: 2% 5%;
    margin-bottom: 5%;
} */

/* added by himanshu */
/* @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.course-builder-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #e5e5e5;
  font-family: "Readex Pro", sans-serif;
  margin: 0 1em;
  margin-bottom: 3em;
}
.course-builder-heading {
  color: #00495f;
  height: 2em;
  font-weight: 600;
  font-size: 25px;
  display: grid;
  align-items: center;
  justify-content: left;
}

.course-builder-top {
  width: 97%;
  padding: 1em;
  background-color: white;
  margin: 0 0 2em 0;
  border-radius: 3px;
}
.course-builder-top-info {
  width: 97%;
  padding: 1em;
  background-color: white;
  margin-bottom: 1em;
  border-radius: 3px;
}
.course-builder-top-table {
  width: 100%;
}
.course-builder-top-thead {
  text-align: left;
}
.course-builder-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
}
.course-builder-inputLable {
  width: 20vw;
}
.course-builder-inputBar {
  width: 20vw;
  height: 4vh;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.course-builder-textarea {
  width: 20vw;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.course-builder-inputSelect {
  width: 20vw;
  height: 4vh;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.course-builder-multipleSelect {
  width: 20vw;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}

.course-builder-button {
  font-family: "Readex Pro", sans-serif;
  background-color: #007c84;
  width: 8vw;
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
}
