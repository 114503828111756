.outer-details-container{
    /* border: 2px solid gray; */
    margin: 54px;
}

.bigger-container-header{
    border: 2px solid lightgray;
    background-color: #241887;
    color: aliceblue;
    font-weight: bold;
    padding: 10px 20px;
    font-size:25px;
    text-align: center;
}

.user-data-bigger-container{
    font-weight: bold;
    padding: 14px;
    margin-top: 16px;
    margin-bottom: 8px;
}

.sortcode-list-style{
    margin: 8px 15px;
    list-style: none;
}

.text-area-box{
    display: flex;
    justify-content: space-between;
    background-color:#e5e5e5;
}

.body-box-header{
    display: flex; 
    /* border:2px solid red; */
    margin:14px 4px;
}

.dummy-box{
    display: flex;
    justify-content: space-between;
    padding: 0px 18px;
    margin:25px 0px;
}

.save-dummy-box{
    display: flex;
    justify-content:space-between;
    margin:14px 12px;
}
/* .btn_addccs{
    width: 10%;
}
.ccs_{
    width: 60%;
} */