@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.input {
  display: none;
}

.label {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: #007c84;
  font-family: "Readex Pro", sans-serif;
}

.label:before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 1px solid #00495f;
  border-radius: 50%;
  z-index: 2;
  transition: box-shadow 0.4s ease, background 0.3s ease;
  margin: 0px 10px -5px 0px;
}

.input:checked + .label:before {
  background: #00495f;
}
