.createQuiz-container {
  width: 97%;
  padding: 1em;
  background-color: white;
  margin-bottom: 1em;
  border-radius: 3px;
}

.createQuiz-btn {
  font-family: "Readex Pro", sans-serif;
  background-color: #007c84;
  width: 8vw;
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
}
.createQuiz-btn-save {
  font-family: "Readex Pro", sans-serif;
  background-color: #007c84;
  width: 8vw;
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
  margin-left: auto;
}
.createQuiz-quizForm {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  padding: 20px;
  margin: 1em;
}

.createQuiz-heading {
  color: #00495f;
  height: 2em;
  font-weight: 600;
  font-size: 25px;
  display: grid;
  align-items: center;
  justify-content: left;
}
.createQuiz-inputBar {
  width: 25vw;
  height: 4vh;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}

.createQuiz-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
}

.createQuiz-inputLabel {
  width: 20vw;
}

.quiz-dragable {
  margin-top: 10px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.quiz-accordion-heading {
  font-family: "Readex Pro", sans-serif;
  font-weight: 600;
  font-size: large;
}
.quiz-accordion-details-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}
